import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Form, Accordion, Button, Card, Col, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SlaDisplay from './SlaDisplay';

export default function MonitoraggioSla(props) {
    const [contrattiLista, setContrattiLista] = useState([]);
    const [contrattoSelezionato, setContrattoSelezionato] = useState(null);

    const [richiesteLista, setRichiesteLista] = useState([]);
    const [contatoriLista, setContatoriLista] = useState([]);

    const [mostraSla, setMostraSla] = useState(false);
    const [ticket, setTicket] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await axios.get('/api/Contratti?filter=monitoraggioSla');
            const data = response.data;

            setContrattiLista(data.contratti);
            if(data.contratti && data.contratti.length > 0)
                setContrattoSelezionato(data.contratti[0]);
        })();
    }, []);

    useEffect(() => {
        if (!contrattoSelezionato) {
            setRichiesteLista([]);
            return;
        }

        const cancelToken = axios.CancelToken.source();
        (async () => {
            const response = await axios.get(contrattoSelezionato.apiSla, { cancelToken: cancelToken.token });
            const data = response.data;

            setContatoriLista(data.contatori);
            setRichiesteLista(data.richieste);
        })();

        return () => cancelToken.cancel();
    }, [contrattoSelezionato]);

    const contrattoSelezionatoValue = contrattoSelezionato ? contrattoSelezionato.id : '';
    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Row>
                    <h3 className="col-sm-12">Gestione SLA</h3>
                </Row>
            </Card.Header>
            <Card.Body>
                <Modal
                    show={mostraSla}
                    onHide={() => setMostraSla(false)}
                    size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Contatori SLA</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!mostraSla ? null : <SlaDisplay ticket={ticket} contatori={contatoriLista} />}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setMostraSla(false)}>Chiudi</Button>
                    </Modal.Footer>
                </Modal>
                {contrattiLista && contrattiLista.length > 1 && <Row className="mb-3">
                    <Form.Control as="select"
                        value={contrattoSelezionatoValue} onChange={ev => setContrattoSelezionato(contrattiLista.find(e => e.id === Number(ev.target.value)))}
                        className="form-control">
                        {contrattiLista.map(e =>
                            <option value={e.id} key={e.id}>{e.descrizione}</option>)}
                    </Form.Control>
                </Row>}
                <Tabs variant='pills' fill md={12} defaultActiveKey="fuori-sla" id="uncontrolled-tab-example">
                    <Tab md={6} eventKey="fuori-sla" title="Ticket fuori SLA">
                        <Row><Col md={12} className="h-100">&nbsp;</Col></Row>
                        <Row><Col md={12} className="h-100">&nbsp;</Col></Row>
                        <Tab.Pane eventKey="fuori-sla">
                            <Accordion>
                                {listaTicketSla(richiesteLista.filter(e => e.tag === 'FuoriOrario'), 'fuori SLA')}
                            </Accordion>
                        </Tab.Pane>
                    </Tab>
                    <Tab md={6} eventKey="in-sla" title="Ticket in SLA">
                        <Row><Col md={12} className="h-100">&nbsp;</Col></Row>
                        <Row><Col md={12} className="h-100">&nbsp;</Col></Row>
                        <Tab.Pane eventKey="in-sla">
                            <Accordion>
                                {listaTicketSla(richiesteLista.filter(e => e.tag !== 'FuoriOrario'), 'in SLA')}
                            </Accordion>
                        </Tab.Pane>
                    </Tab>
                </Tabs>
            </Card.Body>
        </Card>
    </Col>;

    function listaTicketSla(listaTicket, testoSla) {
        if (listaTicket.length === 0)
            return <p>Non ci sono ticket {testoSla}</p>;

        const groups = [];
        for (var i in listaTicket) {
            const richiesta = listaTicket[i];
            var sezione = groups.find(e => e.nome === richiesta.stato);
            if (!sezione)
                groups.push(sezione = { nome: richiesta.stato, tickets: [] });

            sezione.tickets.push(richiesta);
        }

        return <>
            {groups.map(e =>
                <Card key={e.nome}>
                    <Accordion.Toggle style={{ cursor: 'pointer', color: '#007bff' }} as={Card.Header} variant="link" eventKey={e.nome}>
                        <h5><span className="text-muted">Stato: </span>{e.nome} ({e.tickets.length})</h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={e.nome}>
                        <Card.Body>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th style={{ width: '20px' }}>Codice</th>
                                        <th>Oggetto</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {e.tickets.map(creaRichiestaPanel)}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            )}
        </>;
    }

    function creaRichiestaPanel(e, i) {
        return <tr key={e.codice}>
            <td className="text-muted small">{e.codice}</td>
            <td>
                <Button onClick={() => { setMostraSla(true); setTicket(e) }} style={{ cursor: 'pointer', color: '#007bff', border: 'none', textAlign: 'left' }} block variant="outline-light">
                    <span>{e.titolo}</span>
                    {e.tag === 'RischioSforamento' ? <i className="fa fa-exclamation-triangle float-right" aria-hidden="true"></i>
                        : null}
                </Button>
            </td>
        </tr>;
    }
}