import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Table, Form, Row } from 'react-bootstrap';
import { Link, useHistory  } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import ValidationErrors from './ValidationErrors';

export default props => {
    const history = useHistory();
    const utenteId = props.match.params.id;
    const [utente, setUtente] = useState({});
    const [email, setEmail] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [url, setUrl] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const location = useLocation();
    const [validationErrors, setValidationErrors] = useState([]);

    useEffect(() => {
        axios
            .get('/api/Utenti/'.concat(utenteId))
            .then(r => r.data)
            .then(d => {
                setUtente(d);
                setDisplayName(d.displayName);
                setEmail(d.email);
                setUrl(d.url);
            });
    }, [utenteId]);


    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Card.Title className="card-title">Modifica utente: <strong>{utente.displayName}</strong></Card.Title>
            </Card.Header>
            <Form>
                <Card.Body className="card-body">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Nome Visibile</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={displayName} onChange={e => setDisplayName(e.target.value)}
                                className="form-control" type="text" />
                            <ValidationErrors errors={validationErrors} campo="username" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>E-Mail</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={email} onChange={e => setEmail(e.target.value)}
                                className="form-control" type="text" />
                            <ValidationErrors errors={validationErrors} campo="email" />
                        </Col>
                    </Form.Group>
                </Card.Body>
                <Card.Footer>
                    <Button type="submit" onClick={onUpdateUtente} disabled={submitting} className="btn btn-primary mr-2 col-sm-3" type="button">Registra modifiche</Button>
                    <Button onClick={() => history.goBack()} disabled={submitting} className="btn btn-danger col-sm-3">Annulla modifiche</Button>
                </Card.Footer>
            </Form>
        </Card>
    </Col>;

    function onUpdateUtente() {
        if (submitting)
            return;
        var erroriLocali = [];
        if (displayName === '')
            erroriLocali.push({ campo: 'username', errore: 'Inserire uno username' });
        if (email === '')
            erroriLocali.push({ campo: 'email', errore: 'Inserire una e-mail' });
        else if (!/^[^@]+@([^\.]+\.)+[^\.]+$/.test(email))
            erroriLocali.push({ campo: 'email', errore: 'Inserire una e-mail valida' });

        setValidationErrors(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        const data = {
            displayName: displayName,
            email: email
        };
        setSubmitting(true);
        axios
            .put('/api/Utenti/'.concat(utenteId), data)
            .finally(() => setSubmitting(false))
            .then(r => r.data)
            .then(d => history.replace(url));
    }
}