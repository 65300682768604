import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Table } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

export default props => {
    const history = useHistory();
    const utenteId = props.match.params.id;
    const [utente, setUtente] = useState({});
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        axios
            .get('/api/Utenti/'.concat(utenteId))
            .then(r => r.data)
            .then(d => {
                setUtente(d);
            });
    }, [utenteId]);

    console.log(utente)

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <h5>Utente: <span>{utente.displayName}</span></h5>
            </Card.Header>
            <Card.Body className="card-body">
                <Table striped>
                    <tbody>
                        <tr>
                            <td>Nome utente</td>
                            <td>{utente.displayName}</td>
                        </tr>
                        <tr>
                            <td>E-Mail</td>
                            <td>{utente.email}</td>
                        </tr>
                        <tr>
                            <td>Attivo</td>
                            <td>{utente.attivo ? 'Sì' : 'No'}</td>
                        </tr>
                    </tbody>
                </Table>
                {!utente.attivo ? null :
                    <>
                        <Link to={'/Admin/Utente/'.concat(utenteId + '/Modifica')} className="btn btn-primary mr-2">Modifica utente</Link>
                        <Button onClick={() => onBloccaUtente()} disabled={submitting} className="btn btn-danger my-2 mr-2">Blocca utente</Button>
                    </>
                }
                <Button onClick={() => history.goBack()} disabled={submitting} className="btn btn-secondary my-2">Indietro</Button>
            </Card.Body>
        </Card>
    </Col>;

    function onBloccaUtente() {
        setSubmitting(true);
        axios
            .patch('/api/Utenti/'.concat(utenteId), { allowLogin: false })
            .finally(() => setSubmitting(false))
            .then(r => r.data)
            .then(d => setUtente(d));
    }
};
