import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import ClientiDisplay from './components/ClientiDisplay';
import ClientiLista from './components/ClientiLista';
import ClientiModifica from './components/ClientiModifica';
import ClientiNuovo from './components/ClientiNuovo';
import ContrattoDisplay from './components/ContrattoDisplay';
import Layout from './components/Layout';
import LoginPane from './components/LoginPane';
import MonitoraggioSla from './components/MonitoraggioSla';
import RecuperoPassword from './components/RecuperoPassword';
import TicketDisplay from './components/TicketDisplay';
import TicketList from './components/TicketList';
import TicketNuovo from './components/TicketNuovo';
import UtenteCambioPassword from './components/UtenteCambioPassword';
import UtentiDisplay from './components/UtentiDisplay';
import UtentiLista from './components/UtentiLista';
import UtentiModifica from './components/UtentiModifica';
import UtentiNuovo from './components/UtentiNuovo';
import './css/local.css';
import withAuthentication from './hoc/withAuthentication';
import { actionUpdateCurrentUser } from './redux-local/CurrentUser';
import * as SideBar from './redux-local/SideBar';



export default function App(props) {
    const dispatch = useDispatch();
    const sidebarSliding = useSelector(s => s.sidebar.sliding);
    const sidebarCollapsed = useSelector(s => s.sidebar.collapsed);

    useEffect(() => { dispatch(actionUpdateCurrentUser()); }, []);

    useEffect(() => {
        const handler = () => dispatch(SideBar.actionSetSliding(window.innerWidth < 991.98));

        window.addEventListener('resize', handler);

        return () => window.removeEventListener('resize', handler);
    });
    useEffect(() => {
        if (sidebarCollapsed)
            window.document.body.classList.add('sidebar-collapse');

        return () => {
            window.document.body.classList.remove('sidebar-collapse');
        };
    }, [sidebarSliding, sidebarCollapsed]);


    const AuthenticatedLayout = withAuthentication(Layout);

    return <Switch>
        <Route exact path="/" component={LoginPane} />
        <Route path="/RecuperoPassword" component={RecuperoPassword} />
        <Route>
            <AuthenticatedLayout>
                <Switch>
                    <Route exact path="/Utente/CambioPassword" component={UtenteCambioPassword} />
                    <Route exact path="/Admin/Cliente" component={ClientiLista} />
                    <Route path="/Admin/Cliente/Nuovo" component={ClientiNuovo} />
                    <Route exact path="/Admin/Cliente/:id" component={ClientiDisplay} />
                    <Route path="/Admin/Cliente/:id/Modifica" component={ClientiModifica} />
                    <Route exact path="/Admin/Utente" component={UtentiLista} />
                    <Route path="/Admin/Utente/Nuovo" component={UtentiNuovo} />
                    <Route exact path="/Admin/Utente/:id" component={UtentiDisplay} />
                    <Route exact path="/Admin/Utente/:id/Modifica" component={UtentiModifica} />
                    <Route path="/Admin/Contratti/:id" component={ContrattoDisplay} />
                    <Route path="/Ticket/Nuovo" component={TicketNuovo} />
                    <Route path="/Ticket/:codice" component={TicketDisplay} />
                    <Route path="/Ticket" component={TicketList} />
                    <Route exact path="/monitoraggioSLA" component={MonitoraggioSla} />
                </Switch>
            </AuthenticatedLayout>
        </Route>
    </Switch>;
}
