import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router';
import { Link, Switch } from 'react-router-dom';

const NavItem = props => <li className="nav-item">
    <Switch>
        <Route path={props.url}>
            <Link to={props.url} className="nav-link active">
                <i className="far fa-circle nav-icon"></i>
                <p>{props.text}</p>
            </Link>
        </Route>
        <Route>
            <Link to={props.url} className="nav-link">
                <i className="nav-icon fas fa-th"></i>
                <p>{props.text}</p>
            </Link>
        </Route>
    </Switch>
</li>;

export default props => {
    var currentUser = useSelector(store => store.currentUser);
    var allowedActions = currentUser && currentUser.allowedActions ? currentUser.allowedActions : [];

    return <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {allowedActions.indexOf('RichiesteElenco') < 0 ? null : <NavItem url="/Ticket" text="Gestione ticket" />}
        {allowedActions.indexOf('RichiesteSla') < 0 ? null : <NavItem url="/MonitoraggioSLA" text="Gestione SLA" />}
        {allowedActions.indexOf('UtentiElenco') < 0 ? null : <NavItem url="/Admin/Utente" text="Gestione utenti" />}
        {allowedActions.indexOf('ClientiElenco') < 0 ? null : <NavItem url="/Admin/Cliente" text="Gestione clienti" />}
    </ul>
}
