var nextToastId = 1;

export const reducer = (state = [], action) => {
    switch (action.type) {
        case 'ui-popup':
            return [...state, {
                id: nextToastId++,
                text: action.data.text,
                kind: action.data.kind
            }];
        case 'ui-popup-clear':
            return [];
        case 'ui-popup-close':
            return state.filter(e => e.id !== action.data.id)
        default:
            return state;
    }
};

export const actionValidationError = ({ key, text }) => ({
    type: 'ui-popup',
    data: {
        key: key,
        text: text,
        kind: 'bg-danger'
    }
});

export const actionClearValidation = () => ({
    type: 'ui-popup-clear',
    data: null
});