import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';

import * as SideBar from './SideBar';
import * as CurrentUser from './CurrentUser';
import * as Toasts from './Toasts';

const totalReducer = combineReducers({
    currentUser: CurrentUser.reducer,
    toasts: Toasts.reducer,
    sidebar: SideBar.reducer
});

export default createStore(totalReducer, applyMiddleware(thunkMiddleware));
