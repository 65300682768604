//import 'bootstrap/dist/js/bootstrap.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker, { unregister } from './registerServiceWorker';
import EasySupportStore from './redux-local/app-store';
import { Provider } from 'react-redux';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <Provider store={EasySupportStore}>
            <App />
        </Provider>
    </BrowserRouter>,
    rootElement);

unregister();

