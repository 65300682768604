import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function SlaDisplay({ ticket, contatori }) {
    return <Row>
        <Col sm={12} md={6}>
            <Table striped>
                <thead>
                    <tr>
                        <th>Stato</th>
                        <th>Gravità</th>
                    </tr>
                </thead>
                <tbody className="monitoraggioSla-richiesteStatoGravita">
                    <tr className="monitoraggioSla-richiestaStatoGravita">
                        <td className="monitoraggioSla-richiestaStato">{ticket.stato}</td>
                        <td className="monitoraggioSla-richiestaGravita">{ticket.gravita}</td>
                    </tr>
                </tbody>
            </Table>
        </Col>
        <Col sm={12} md={6}>
            <Table className="table table-striped">
                <thead>
                    <tr>
                        <th>Stato</th>
                        <th>Tempo</th>
                    </tr>
                </thead>
                <tbody className="monitoraggioSla-richiesteStatoGravita">
                    {contatori.map((c, i) => <tr key={i}>
                        <td>{c}</td>
                        <td>{(ticket.contatori[c].totalMinutes || 0).toFixed(0)} minuti</td>
                    </tr>)}
                </tbody>
            </Table>
        </Col>
        <Col>
            <Link to={'/Ticket/'.concat(ticket.codice)} className="btn btn-primary">
                Visualizza Ticket
            </Link>
        </Col>
    </Row>
}