import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import { actionClearValidation } from '../redux-local/Toasts';
import { useEffect } from 'react';

export default props => {
    const location = useLocation();
    const dispatch = useDispatch();
    const toasts = useSelector(s => s.toasts);

    useEffect(() => {
        dispatch(actionClearValidation());
    }, [location.pathname]);

    return <div className="toasts-top-right fixed">
        {toasts.map(e => <Toast delay={3000} autohide key={e.id} className={e.kind} onClose={() => dispatch({
            type: 'ui-popup-close', data: { id: e.id }
        })}>
            <Toast.Header>Easy Support</Toast.Header>
            <Toast.Body>{e.text}</Toast.Body>
        </Toast>)}
    </div>;
};
