import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Table, Button, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ContrattoNuovo from './ContrattoNuovo';

export default props => {
    const history = useHistory();
    const [cliente, setCliente] = useState(() => ({
        contratti: []
    }));
    const [submitting, setSubmitting] = useState(false);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        axios
            .get('/api/Clienti/'.concat(props.match.params.id))
            .then(r => r.data)
            .then(d => setCliente(d))
    }, [props.match.params.id]);

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <h5>Cliente: <span>{cliente.ragioneSociale}</span></h5>
            </Card.Header>
            <Card.Body>
                <Table striped>
                    <tbody>
                        <tr>
                            <td>Ragione sociale</td>
                            <td>{cliente.ragioneSociale}</td>
                        </tr>
                        <tr>
                            <td>Partita IVA</td>
                            <td>{cliente.partitaIva}</td>
                        </tr>
                        <tr>
                            <td>Indirizzo</td>
                            <td>{cliente.indirizzo}</td>
                        </tr>
                        <tr>
                            <td>Città</td>
                            <td>{cliente.città}</td>
                        </tr>
                        <tr>
                            <td>Provincia</td>
                            <td style={{textTransform: 'uppercase'}}>{cliente.provincia}</td>
                        </tr>
                        <tr>
                            <td>E-mail</td>
                            <td>{cliente.email}</td>
                        </tr>
                        <tr>
                            <td>Referente</td>
                            <td>{cliente.referente}</td>
                        </tr>
                        <tr>
                            <td>Attivo</td>
                            <td>{cliente.cancellato ? "No" : "Sì"}</td>
                        </tr>
                    </tbody>
                </Table>
                {cliente.cancellato ? null : <>
                    <Link to={'/Admin/Cliente/'.concat(props.match.params.id + '/Modifica')} className="btn btn-primary mt-2 mr-2">Modifica cliente</Link>
                    <Button onClick={() => onDeleteCliente()} className="btn btn-danger mt-2 mr-2">Cancella cliente</Button>
                </>
                }
                <Button onClick={() => history.goBack()} className="btn btn-secondary mt-2">Indietro</Button>
            </Card.Body>
        </Card>
        <Card>
            <Card.Header>
                <Row>
                    <h5 className="col-md-3 col-sm-12">Contratti</h5>
                    <Button onClick={() => setAdding(true)} className="btn btn-primary col-md-3 col-sm-12 ml-auto">Registra nuovo contratto</Button>
                    {adding ? <ContrattoNuovo id={props.match.params.id} mostra={adding} onHide={() => setAdding(false)} /> : null}
                </Row>
            </Card.Header>
            <Card.Body>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Contratto</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cliente.contratti.map((e, i) => <tr key={i}>
                            <td>
                                <Link to={e.viewUrl}>
                                    {e.descrizione}
                                </Link>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </Col>;

    function onDeleteCliente() {
        if (submitting)
            return;

        setSubmitting(true);
        axios
            .delete('/api/Clienti/'.concat(props.match.params.id))
            .finally(() => setSubmitting(false))
            .then(r => axios
                .get('/api/Clienti/'.concat(props.match.params.id))
                .then(r => r.data)
                .then(d => setCliente(d)))
    }
};
