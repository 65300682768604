import axios from 'axios';

const defaultState = null;

export const anonymousUser = {
    isAnonymous: true,
    displayName: 'Utente anonimo',
    allowedActions: []
};

const actionResetCurrentUser = () => ({
    type: 'currentUser-reset',
    data: null
});

export const actionLogin = (params, decoratePromise) => (dispatch, getState) => {
    const promise = axios
        .post('/api/Login', {
            'username': params.username,
            'password': params.password
        })
        .then(r => r.data)
        .then(jres => {
            dispatch(actionResetCurrentUser());
            dispatch(actionUpdateCurrentUser());

            return {
                success: jres.success
            };
        });

    if (typeof (decoratePromise) === 'function')
        decoratePromise(promise);
};

export const actionLogout = () => (dispatch, getState) => {
    return axios
        .delete('/api/Login')
        .then(
            () => dispatch(actionUpdateCurrentUser())
        );
};

var loadCurrentRequest = null;
export const actionUpdateCurrentUser = () => (dispatch, getState) => {
    if (loadCurrentRequest)
        return loadCurrentRequest;

    loadCurrentRequest = axios
        .get('/api/Utenti/Corrente', { validateStatus: function (s) { return s === 200 || s === 404; } })
        .finally(() => { loadCurrentRequest = null })
        .then(r => {
            switch (r.status) {
                case 200:
                    dispatch({ type: 'currentUser-change', data: r.data });
                    break;
                case 404:
                    dispatch({
                        type: 'currentUser-change', data: {
                            user: anonymousUser
                        }
                    });
                    break;
            }
        })

    return loadCurrentRequest;
};

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'currentUser-change':
            return {
                ...action.data.user,
                displayName: action.data.user.displayName,
                allowedActions: action.data.user.allowedActions
            };
        case 'currentUser-reset':
            return defaultState;
        default:
            return state;
    }
};
