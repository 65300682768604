import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ValidationErrors from './ValidationErrors';
import axios from 'axios';

export default props => {
    const [passwordCorrente, setPasswordCorrente] = useState('');
    const [passwordNuova, setPasswordNuova] = useState('');
    const [passwordNuovaCheck, setPasswordNuovaCheck] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    React.createElement(Card.Header, {
        md: { span: 10, offset: 1 },
        children: [
            React.createElement(Card.Title, {
                className: 'card-title',
                children: ['Cambia password']
            })
        ]
    })

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Card.Title className="card-title">Cambia password</Card.Title>
            </Card.Header>
            <Form>
                <Card.Body className="card-body">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Password corrente</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={passwordCorrente} onChange={e => setPasswordCorrente(e.target.value)}
                                disabled={submitting} className="form-control" type="password" />
                            <ValidationErrors errors={validationErrors} campo="passwordCorrente" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Password nuova</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={passwordNuova} onChange={e => setPasswordNuova(e.target.value)}
                                disabled={submitting} className="form-control" type="password" />
                            <ValidationErrors errors={validationErrors} campo="passwordNuova" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Conferma password nuova</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={passwordNuovaCheck} onChange={e => setPasswordNuovaCheck(e.target.value)}
                                disabled={submitting} className="form-control" type="password" />
                            <ValidationErrors errors={validationErrors} campo="passwordNuovaCheck" />
                        </Col>
                    </Form.Group>
                </Card.Body>
                <Card.Footer className="card-footer">
                    <Button
                        onClick={onSubmit} disabled={submitting} variant="primary"
                        block className="mt-5 col-sm-3">Salva</Button>
                </Card.Footer>
            </Form>
        </Card>
    </Col>;

    function onSubmit() {
        var erroriLocali = [];
        if (passwordCorrente === '')
            erroriLocali.push({ campo: 'passwordCorrente', errore: 'Inserire la password corrente' });
        if (passwordNuova === '')
            erroriLocali.push({ campo: 'passwordNuova', errore: 'Inserire una password' });
        else {
            if (passwordNuova !== passwordNuovaCheck)
                erroriLocali.push({ campo: 'passwordNuovaCheck', errore: 'Le password non corrispondono' });

            if (passwordNuova.length < 8)
                erroriLocali.push({ campo: 'passwordNuova', errore: 'La password deve essere lunga almeno otto caratteri' });
            if (!/[a-z]/.test(passwordNuova))
                erroriLocali.push({ campo: 'passwordNuova', errore: 'La password deve contenere almeno una lettera minuscola' });
            if (!/[A-Z]/.test(passwordNuova))
                erroriLocali.push({ campo: 'passwordNuova', errore: 'La password deve contenere almeno una lettera maiuscola' });
            if (!/[0-9]/.test(passwordNuova))
                erroriLocali.push({ campo: 'passwordNuova', errore: 'La password deve contenere almeno un numero' });
        }

        setValidationErrors(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        var noexcept = false;
        setSubmitting(true);
        try {
            const postData = {
                kind: 'password',
                passwordCorrente: passwordCorrente,
                passwordNuova: passwordNuova
            };

            axios
                .post('/api/Utenti/Corrente', postData, { validateStatus: s => (s === 200 || s === 409) })
                .finally(() => setSubmitting(false))
                .then(r => {
                    switch (r.status) {
                        case 200:
                            props.history.push('/Ticket');
                            break;
                        case 409:
                            const validationErrors = r.data.validazione;

                            setValidationErrors(validationErrors.filter(e => e.campo === 'passwordCorrente').map(e => ({
                                campo: 'passwordCorrente',
                                errore: e.errore
                            })));
                            break;
                    }
                });

            noexcept = true;
        } finally {
            if (!noexcept)
                setSubmitting(false);
        }
    }
};
