import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

export default Component => props => {
    const currentUser = useSelector(s => s.currentUser);

    if (currentUser === null)
        return <> </>;

    return currentUser.isAnonymous ? <Redirect to="/" /> : <Component {...props} />;
};
