import axios from 'axios';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Modal, Alert } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ValidationErrors from './ValidationErrors';

export default ({ mostra, onHide, id }) => {
    const [descrizione, setDescrizione] = useState('');
    const [descrizioneAssistenza, setDescrizioneAssistenza] = useState('');
    const [erroriValidazione, setErroriValidazione] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();


    return <>
        <Modal show={mostra} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Crea un nuovo contratto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>Nome Contratto</Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            value={descrizione} onChange={e => setDescrizione(e.target.value)}
                            disabled={submitting} className="form-control" type="text" />
                        <ValidationErrors errors={erroriValidazione} campo="descrizione" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={4}>Descrizione Assistenza</Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            value={descrizioneAssistenza} onChange={e => setDescrizioneAssistenza(e.target.value)}
                            disabled={submitting} className="form-control" type="text" />
                        <ValidationErrors errors={erroriValidazione} campo="descrizioneAssistenza" />
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Annulla
          </Button>
                <Button disabled={submitting} variant="primary" onClick={onContrattoSubmit}>
                    Salva
          </Button>
            </Modal.Footer>
        </Modal>
    </>;

    function onContrattoSubmit() {
        const erroriLocali = [];

        if (descrizione.trim() === '')
            erroriLocali.push({ campo: 'descrizione', errore: 'Inserire un nome per il contratto' });
        if (descrizione.trim() === '')
            erroriLocali.push({ campo: 'descrizioneAssistenza', errore: "Inserire la descrizione dell'assistenza" });

        setErroriValidazione(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        const postData = {
            clienteId: id,
            descrizione: descrizione,
            casellaAssistenza: 'f.bellia@easy-net.it',
            descrizioneAssistenza: descrizioneAssistenza
        };
        setSubmitting(true);
        axios
            .post('/api/Contratti', postData)
            .finally(() => setSubmitting(false))
            .then(r => r.data)
            .then(d => history.replace(d.url));
    }
}
