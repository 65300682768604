import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactRTE from 'react-rte';
import FileUploader from './FileUploader';

const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Grassetto', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Corsivo', style: 'ITALIC' },
        { label: 'Sottolineato', style: 'UNDERLINE' }
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'Elenco puntato', style: 'unordered-list-item' },
        { label: 'Elenco numerato', style: 'ordered-list-item' }
    ],
    HISTORY_BUTTONS: {
        undo: { label: 'Annulla' },
        redo: { label: 'Ripeti' }
    }
};

export default function TicketDisplay(props) {
    const codice = props.match.params.codice;
    const dispatch = useDispatch();
    const [ticket, setTicket] = useState({ attivita: [], allowedActions: {} });
    const [nuovaAttivitaCommento, setNuovaAttivitaCommento] = useState(() => ReactRTE.createEmptyValue());
    const [nuovaNotaCommento, setNuovaNotaCommento] = useState(() => ReactRTE.createEmptyValue());
    const [submitting, setSubmitting] = useState(false);
    const [commentiDaVisualizzare, setCommentiDaVisualizzare] = useState(null);
    const [commentoFileObject, setCommentoFileObject] = useState(null);
    const [commentoFileDataUri, setCommentoFileDataUri] = useState(null);
    const [commentoFileInputClearKey, setCommentoFileInputClearKey] = useState(0);
    const [attivitaFileObject, setAttivitaFileObject] = useState(null);
    const [attivitaFileDataUri, setAttivitaFileDataUri] = useState(null);
    const [attivitaFileInputClearKey, setAttivitaFileInputClearKey] = useState(0);

    function getTicket() {
        axios
            .get('/api/Richieste/'.concat(codice))
            .then(r => r.data)
            .then(d => {
                const ticket = d;
                for (var i in ticket.attivita) {
                    const attività = ticket.attivita[i];

                    attività.timeStamp = new Date(Date.parse(attività.timeStamp));

                    for (var j in attività.commenti) {
                        const commenti = attività.commenti[j];
                        commenti.timeStamp = new Date(Date.parse(commenti.timeStamp));
                    }
                }
                setTicket(ticket);
            });
    }

    useEffect(() => {
        getTicket();
    }, []);

    useEffect(() => {
        if (commentoFileObject) {
            var reader = new FileReader();
            reader.onload = e => setCommentoFileDataUri(e.target.result);
            reader.readAsDataURL(commentoFileObject);

            return () => {
                if (reader.readyState === 'LOADING')
                    reader.abort();
            };
        }
    }, [commentoFileObject]);

    useEffect(() => {
        if (attivitaFileObject) {
            var reader = new FileReader();
            reader.onload = e => setAttivitaFileDataUri(e.target.result);
            reader.readAsDataURL(attivitaFileObject);

            return () => {
                if (reader.readyState === 'LOADING')
                    reader.abort();
            };
        }
    }, [attivitaFileObject]);

    const mostraNoteAssistenza =
        ticket.noteAssistenza ||
        (ticket.allegatiAssistenza && ticket.allegatiAssistenza.length > 0);
    return <Col md={{ span: 10, offset: 1 }}>
        <Row>
            <Col md={12}>
                <Card>
                    <Card.Header className="bg-primary">
                        <h5>Ticket #<span>{codice}</span></h5>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <span className="col-form-label label">Stato Ticket: </span>
                                <span>{ticket.stato}</span>
                            </Col>
                        </Row>
                        {!ticket.allegato ? null : <Row>
                            <Col md={12}>
                                <span className="col-form-label label">Allegato: </span>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip id={`tooltip-right`}>
                                            Scarica
                                        </Tooltip>
                                    }>
                                    <span>
                                        <a href={ticket.allegato} download=""><i className="fas fa-download" style={{ fontSize: '24px' }} aria-hidden="true"></i></a>
                                    </span>
                                </OverlayTrigger>
                            </Col>
                        </Row>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Card>
            <Card.Header className="bg-primary">
                <h5>Oggetto: <span>{ticket.titolo}</span></h5>
            </Card.Header>
            <Card.Body className="bg-light">
                <Modal
                    show={!!(commentiDaVisualizzare)}
                    onHide={() => setCommentiDaVisualizzare(null)}
                    size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Note</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!commentiDaVisualizzare ? null : commentiDaVisualizzare.map((e, i) => <Row key={i}>
                            <Col md={12}>
                                <Row className="p-2 m-0 rounded-top"
                                    style={{ backgroundColor: 'rgb(145, 191, 242)' }}>
                                    <Col className="text-left">{e.timeStamp.toLocaleString()}</Col>
                                    <Col className="text-right">{e.utenteDesc}</Col>
                                </Row>
                                <ReactRTE className="border-0 col-md-12" value={ReactRTE.createValueFromString(e.testo, 'markdown')} readOnly />
                            </Col>
                            <Col md={12}>
                                <Row className="bg-white p-2 m-0">
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`tooltip-right`}>
                                                Scarica Allegato
                                            </Tooltip>
                                        }>
                                        <span>
                                            {!e.allegatoUrl ? null : <a href={e.allegatoUrl} download=""><i className="fas fa-download" style={{ fontSize: '24px' }} aria-hidden="true"></i></a>}
                                        </span>
                                    </OverlayTrigger>
                                </Row>
                            </Col>
                        </Row>)}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setCommentiDaVisualizzare(null)}>Chiudi</Button>
                    </Modal.Footer>
                </Modal>
                {ticket.attivita.map((e, i) => <Row key={i}>
                    <Col md={12}>
                        <Row className="p-2 m-0 rounded-top text-center"
                            style={{ backgroundColor: 'rgb(145, 191, 242)' }}>
                            <Col className="text-left">{e.statoDesc}</Col>
                            <Col className="text-center">{e.timeStamp.toLocaleString()}</Col>
                            <Col className="text-right">{e.username}</Col>
                        </Row>
                    </Col>
                    <Col md={12}>
                        <Row className="bg-white p-2 m-0">
                            <ReactRTE className="border-0 col-md-12" value={ReactRTE.createValueFromString(e.description, 'markdown')} readOnly />
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Visualizza Note
                                    </Tooltip>
                                }>
                                <span className="float-right">{!(e.commenti && e.commenti.length > 0) ? null :
                                    <Link className="mr-2"
                                        onClick={() => setCommentiDaVisualizzare(e.commenti)}><i className="far fa-edit" style={{ fontSize: '24px' }} aria-hidden="true"></i>
                                    </Link>}
                                </span>
                            </OverlayTrigger>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Scarica Allegato
                                    </Tooltip>
                                }>
                                <span>
                                    {!e.allegatoUrl ? null : <a href={e.allegatoUrl} download=""><i className="fas fa-download" style={{ fontSize: '24px' }} aria-hidden="true"></i></a>}
                                </span>
                            </OverlayTrigger>
                        </Row>
                    </Col>
                </Row>)}
            </Card.Body>
        </Card>
        {!mostraNoteAssistenza ? null : <Card>
            <Card.Header className="bg-primary">
                <h5>Note per l'assistenza</h5>
            </Card.Header>
            <Card.Body>
                <Col xs="12">{ticket.noteAssistenza}</Col>
                {ticket.allegatiAssistenza.map(e => <Col>
                    {!e.image ? null : <img height="32" src={e.image} />}
                    <a href={e.url} download="">{e.nome}</a>
                </Col>)}
            </Card.Body>
        </Card>}
        {(function () {
            switch (ticket.stato) {
                case 'Chiuso':
                    return <Col>
                        <p className="bg-warning p-2 w-100 text-center rounded">Questo Ticket è chiuso e non può essere riaperto</p>
                    </Col>;
                case 'Annullato':
                    return <Col>
                        <p className="bg-warning p-2 w-100 text-center rounded">Questo Ticket è annullato e non può essere riaperto</p>
                    </Col>;
                default:
                    return <Tab.Container>
                        <Tabs variant='pills' className="p-2 border" fill defaultActiveKey="aggiungi-nota" id="uncontrolled-tab-example">
                            <Tab eventKey="aggiungi-nota" title="Nota">
                                <Tab.Pane className="p-2 border" eventKey="aggiungi-nota">
                                    <ReactRTE toolbarConfig={toolbarConfig} value={nuovaNotaCommento} onChange={(e) => setNuovaNotaCommento(e)} />
                                    <Row>
                                        <Col md={6}>
                                            <Button
                                                disabled={submitting}
                                                onClick={onNuovaNotaCommento}
                                                className="my-2 col-md-5">Aggiungi nota</Button>
                                            </Col>
                                            <Col md={6}>
                                            <FileUploader key={commentoFileInputClearKey} className="btn btn-light text-left mt-2 pt-2" custom label="Seleziona un file"
                                                onChange={onCommentoFileSelected} />
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab>
                            {Object.keys(ticket.allowedActions).length === 0 ? null : <Tab eventKey="aggiorna-stato" title="Aggiorna Stato">
                                <Tab.Pane className="p-2 border" eventKey="aggiorna-stato">
                                    <ReactRTE toolbarConfig={toolbarConfig} value={nuovaAttivitaCommento} onChange={(e) => setNuovaAttivitaCommento(e)} />
                                        <Row>
                                            <Col md={6}>
                                                {Object.keys(ticket.allowedActions).map((e, i) =>
                                                    <Button
                                                        key={i} disabled={submitting} onClick={onClickFactory(e)}
                                                        className="my-2 mr-2 col-md-5"
                                                    >{ticket.allowedActions[e].etichetta}
                                                    </Button>)}
                                            </Col>

                                            <Col md={6}>
                                                <FileUploader key={attivitaFileInputClearKey} className="btn btn-light text-left pt-2 mt-2" custom label="Seleziona un file"
                                                    onChange={onAttivitaFileSelected} />
                                            </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab>}
                        </Tabs>
                    </Tab.Container>;
            }
        })()}
    </Col>;

    function onCommentoFileSelected(e) {
        if (e.target.files.length > 0)
            setCommentoFileObject(e.target.files[0]);
        else
            setCommentoFileObject(null);
    }

    function onAttivitaFileSelected(e) {
        if (e.target.files.length > 0)
            setAttivitaFileObject(e.target.files[0]);
        else
            setAttivitaFileObject(null);
    }

    function onNuovaNotaCommento() {
        const messaggio = nuovaNotaCommento.toString('markdown').trim();

        if (messaggio.length && messaggio.charCodeAt(0) === 8203) {
            dispatch({
                type: 'ui-popup',
                data: {
                    text: "È necessario inserire il corpo della nota",
                    kind: 'bg-danger'
                }
            });
            return;
        }

        const postData = {
            messaggio: messaggio,
            allegatoDataUri: commentoFileDataUri,
            allegatoFilename: commentoFileObject ? commentoFileObject.name : null
        };

        setSubmitting(true);
        axios
            .post('/api/Richieste/'.concat(codice, '/Commenti'), postData)
            .finally(() => setSubmitting(false))
            .then(d => {
                setNuovaNotaCommento(ReactRTE.createEmptyValue());
                setCommentoFileInputClearKey(commentoFileInputClearKey + 1);
                setTicket({
                    ...ticket,
                    commenti: d.commenti
                });
                getTicket()
            });
    }

    function onClickFactory(v) {
        return (e) => {
            const commento = nuovaAttivitaCommento.toString('markdown').trim();

            if (commento.length && commento.charCodeAt(0) === 8203) {
                dispatch({
                    type: 'ui-popup',
                    data: {
                        text: "Il campo attività non può essere vuoto",
                        kind: 'bg-danger'
                    }
                });
                return;
            }

            const postData = {
                azione: v,
                commento: commento,
                allegatoFilename: attivitaFileObject ? attivitaFileObject.name : null,
                allegatoDataUri: attivitaFileDataUri
            };

            setSubmitting(true);
            axios
                .post('/api/Richieste/'.concat(codice), postData)
                .finally(() => setSubmitting(false))
                .then(r => {
                    setNuovaAttivitaCommento(ReactRTE.createEmptyValue());
                    setAttivitaFileInputClearKey(attivitaFileInputClearKey + 1);
                    getTicket();
                });
        };
    }
}
