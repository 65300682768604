import React from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import withAuthentication from '../hoc/withAuthentication';
import * as SideBar from '../redux-local/SideBar';
import NavBar from './NavBar';
import ToastsHost from './ToastsHost';
import UserPanel from './UserPanel';

export default function Layout(props) {
    const dispatch = useDispatch();

    return <div className="wrapper">
        {/*<!-- Navbar -->*/}
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <button
                        onClick={() => dispatch(SideBar.actionToggleCollapsed())}
                        className="nav-link" data-widget="pushmenu" type="button" style={{ border: 'none', background: 'transparent' }}>
                        <i className="fas fa-bars"></i>
                    </button>
                </li>
            </ul>
        </nav>
        {/*<!-- /.navbar -->*/}
        {/*<!-- Main Sidebar Container -->*/}
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            {/*<!-- Brand Logo -->*/}
            <a href="/Ticket" className="brand-link">
                <h1 className="brand-text font-weight-light text-center">easy<span className="text-primary">support</span></h1>
            </a>

            {/*<!-- Sidebar -->*/}
            <div className="sidebar">
                {/*<!-- Sidebar user panel (optional) -->*/}
                <Nav className="mt-2 border-bottom">
                    <UserPanel />
                </Nav>

                {/*<!-- SidebarSearch Form -->*/}
                {/*<Form inline>*/}
                {/*    <InputGroup>*/}
                {/*        <FormControl*/}
                {/*            className="form-control form-control-sidebar"*/}
                {/*            type="search"*/}
                {/*            placeholder="Cerca"*/}
                {/*            aria-label="Search"*/}
                {/*        />*/}
                {/*        <InputGroup.Append>*/}
                {/*            <Button className="btn btn-sidebar">*/}
                {/*                <i className="fas fa-search fa-fw"></i>*/}
                {/*            </Button>*/}
                {/*        </InputGroup.Append>*/}
                {/*    </InputGroup>*/}
                {/*</Form>*/}
                {/*<!-- Sidebar Menu -->*/}
                <Nav className="mt-2">
                    <NavBar />
                </Nav>
                {/*<!-- /.sidebar-menu -->*/}
            </div>
            {/*<!-- /.sidebar -->*/}
        </aside>

        {/*<!-- Content Wrapper. Contains page content -->*/}
        <div className="content-wrapper">
            {/*<!-- Content Header (Page header) -->*/}
            <div className="content-header">

            </div>
            {/*<!-- /.content-header -->*/}
            {/*<!-- Main content -->*/}
            <div className="content">
                {props.children}
            </div>
            {/*<!-- /.content -->*/}
        </div>
        {/*<!-- /.content-wrapper -->*/}
        {/*<!-- Control Sidebar -->*/}
        <aside className="control-sidebar control-sidebar-dark position-fixed">
            {/*<!-- Control sidebar content goes here -->*/}
        </aside>
        {/*<!-- /.control-sidebar -->*/}
        {/*<!-- Main Footer -->*/}
        <footer className="main-footer">
            <div className="row">
                <div className="col-8">
                    <p><strong>Copyright &copy; 2021 <a href="http://www.easy-net.it/" target="_blank">Easy Net</a>. </strong>
            Tutti i diritti riservati.</p>
                </div>
                <div className="float-right col-2 text-right">V1.1.1</div>
                <div className="float-right col-2 text-right">
                    <img src="/img/logo/easy-net-iso-9001-68.png" alt="Logo qualità Easy Net ISO 9001" style={{ width: '68px' }} />
                </div>
            </div>
        </footer>
        {/*<!-- ./wrapper -->*/}
        <div id="sidebar-overlay"></div>
        <ToastsHost />
    </div>;
}