import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Table, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ValidationErrors from './ValidationErrors';

export default props => {
    const history = useHistory();

    const [ragioneSociale, setRagioneSociale] = useState('');
    const [partitaIva, setPartitaIva] = useState('');
    const [indirizzo, setIndirizzo] = useState('');
    const [città, setCittà] = useState('');
    const [provincia, setProvincia] = useState('');
    const [email, setEmail] = useState('');
    const [referente, setReferente] = useState('');
    const [url, setUrl] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    const [cliente, setCliente] = useState(() => ({
        contratti: []
    }));

    useEffect(() => {
        axios
            .get('/api/Clienti/'.concat(props.match.params.id))
            .then(r => r.data)
            .then(d => {
                setCliente(d);
                setRagioneSociale(d.ragioneSociale);
                setPartitaIva(d.partitaIva);
                setIndirizzo(d.indirizzo);
                setCittà(d.città);
                setProvincia(d.provincia);
                setEmail(d.email);
                setReferente(d.referente);
                setUrl(d.url);
            });
    }, [props.match.params.id]);

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Card.Title className="card-title">Modifica cliente: <strong>{cliente.ragioneSociale}</strong></Card.Title>
            </Card.Header>
            <Form>
                <Card.Body className="card-body">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Ragione Sociale</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={ragioneSociale} onChange={e => setRagioneSociale(e.target.value)}
                                className="form-control" type="text" pattern="^\d{11}$" />
                            <ValidationErrors errors={validationErrors} campo="ragioneSociale" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Partita IVA</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={partitaIva} onChange={e => setPartitaIva(e.target.value)}
                                className="form-control" type="text" maxlength="11" />
                            <ValidationErrors errors={validationErrors} campo="partitaIva" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Indirizzo</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={indirizzo} onChange={e => setIndirizzo(e.target.value)}
                                className="form-control" type="text" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Città</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={città} onChange={e => setCittà(e.target.value)}
                                className="form-control" type="text" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Provincia</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={provincia} onChange={e => setProvincia(e.target.value.toUpperCase())}
                                className="form-control" type="text" maxlength="2" />
                            <ValidationErrors errors={validationErrors} campo="provincia" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>E-Mail</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={email} onChange={e => setEmail(e.target.value)}
                                className="form-control" type="text" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Referente</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={referente} onChange={e => setReferente(e.target.value)}
                                className="form-control" type="text" />
                        </Col>
                    </Form.Group>
                </Card.Body>
                <Card.Footer>
                    <Button type="submit" onClick={onUpdateCliente} disabled={submitting} className="btn btn-primary mr-2 col-sm-3" type="button">Registra modifiche</Button>
                    <Button onClick={() => history.goBack()} disabled={submitting} className="btn btn-danger col-sm-3">Annulla modifiche</Button>
                </Card.Footer>
            </Form>
        </Card>
    </Col>;

    function onUpdateCliente() {
        if (submitting)
            return;

        var erroriLocali = [];
        if (ragioneSociale === '')
            erroriLocali.push({ campo: 'ragioneSociale', errore: 'Inserire la ragione sociale' });
        if (partitaIva === '')
            erroriLocali.push({ campo: 'partitaIva', errore: 'Inserire la partita IVA' });
        else if (!/^\d{11}$/.test(partitaIva))
            erroriLocali.push({ campo: 'partitaIva', errore: 'La partita IVA deve contenere 11 numeri' });
        if(provincia !== '')
            if (!/^[a-zA-Z]{2}$/i.test(provincia))
            erroriLocali.push({ campo: 'provincia', errore: 'La provincia deve contenere 2 lettere' });

        setValidationErrors(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        const data = {
            ragioneSociale: ragioneSociale,
            partitaIva: partitaIva,
            indirizzo: indirizzo,
            città: città,
            provincia: provincia,
            email: email,
            referente: referente
        };
        setSubmitting(true);
        axios
            .put('/api/Clienti/'.concat(props.match.params.id), data)
            .finally(() => setSubmitting(false))
            .then(r => r.data)
            .then(d => history.replace(url));
    }
};
