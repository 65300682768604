import axios from 'axios';
import { Modal } from 'react-bootstrap';
import React, { useState } from 'react';
import { Button, Card, Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { actionValidationError } from '../redux-local/Toasts';
import ToastsHost from './ToastsHost';
import ValidationErrors from './ValidationErrors';
import { actionLogin } from '../redux-local/CurrentUser';

export default function LoginPane(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [submitting, setSubmitting] = useState(false);

    const [recuperoModalShow, setRecuperoModalShow] = useState(false);
    const [recuperoModalEmail, setRecuperoModalEmail] = useState('');
    const [recuperoModalSent, setRecuperoModalSent] = useState(false);

    const [validationErrors, setValidationErrors] = useState([]);

    const dispatch = useDispatch();

    var recuperoModalBody;
    if (recuperoModalSent)
        recuperoModalBody = <Row>
            <Col md={12}>
                Se l'indirizzo {recuperoModalEmail} corrisponde ad un'utenza attiva,
                sarà inviato a breve un messaggio con le informazioni di recupero.
            </Col>
        </Row>
    else
        recuperoModalBody = <Form.Group>
            <Form.Label>Inserisci l'e-mail associata all'utenza</Form.Label>
            <Form.Control
                value={recuperoModalEmail} onChange={e => setRecuperoModalEmail(e.target.value)}
                disabled={submitting} className="form-control" type="email" />
            <ValidationErrors errors={validationErrors} campo="recuperoModalEmail" />
        </Form.Group>;

    return <div className="login-page">
        <div className="login-box">
            <Modal show={recuperoModalShow} size="lg" onHide={() => setRecuperoModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Recupera password</Modal.Title>
                </Modal.Header>
                <Modal.Body>{recuperoModalBody}</Modal.Body>
                <Modal.Footer>
                    {recuperoModalSent ? null : <>
                        <Button variant="success" onClick={onPasswordDimenticata}>Conferma recupero</Button>
                        <Button variant="danger" onClick={() => setRecuperoModalShow(false)}>Annulla</Button>
                    </>}
                </Modal.Footer>
            </Modal>
            {/*<!-- /.login-logo -->*/}
            <Card className="card card-outline card-primary">
                <Card.Header className="card-header text-center">
                    <h1 className="brand-text font-weight-light text-center">easy<span className="text-primary">support</span></h1>
                </Card.Header>
                <Card.Body className="card-body">
                    <p className="login-box-msg">Esegui l'accesso al servizio</p>
                    <InputGroup className="mb-3">
                        <FormControl value={username} onChange={e => setUsername(e.target.value)} onKeyDown={e => e.key === 'Enter' ? onLogin() : null} disabled={submitting} type="email" className="form-control" placeholder="Username" />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                <span className="fas fa-envelope"></span>
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <FormControl value={password} onChange={e => setPassword(e.target.value)} onKeyDown={e => e.key === 'Enter' ? onLogin() : null} disabled={submitting} type="password" className="form-control" placeholder="Password" />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                <span className="fas fa-lock"></span>
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <Row>
                        <Col md={{ offset: 8, span: 4 }}>
                            <Button onClick={onLogin} disabled={submitting} type="submit" className="btn btn-primary btn-block">Accedi</Button>
                        </Col>
                        {/*<!-- /.col -->*/}
                    </Row>
                    {/*<!-- /.social-auth-links -->*/}
                    <p className="mb-1">
                        <Link to="/" onClick={() => { setRecuperoModalShow(true); setRecuperoModalSent(false); }}>Ho dimenticato la password</Link>
                    </p>
                </Card.Body>
                {/*<!-- /.card-body -->*/}
            </Card>
            {/*<!-- /.card -->*/}
        </div>
        <ToastsHost />
    </div>;

    function onLogin() {
        setSubmitting(true);

        dispatch(actionLogin(
            { 'username': username, 'password': password },
            p => p
                .finally(() => setSubmitting(false))
                .then(result => {
                    if (result.success)
                        props.history.replace('/Ticket');
                    else
                        dispatch(actionValidationError({
                            key: 'login-invalid',
                            text: 'Nome utente o password non validi'
                        }));
                })));
    }

    function onPasswordDimenticata() {
        const erroriLocali = [];
        if (recuperoModalEmail === '')
            erroriLocali.push({ campo: 'recuperoModalEmail', errore: 'Inserire una e-mail' });
        else if (!/^[^@]+@([^\.]+\.)+[^\.]+$/.test(recuperoModalEmail))
            erroriLocali.push({ campo: 'recuperoModalEmail', errore: 'Inserire una e-mail valida' });

        setValidationErrors(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        var noexcept = false;
        setSubmitting(true);
        try {
            const postData = {
                email: recuperoModalEmail
            };

            axios
                .post('/api/Utenti/RichiesteRecupero', postData)
                .finally(() => setSubmitting(false))
                .then(r => setRecuperoModalSent(true));

            noexcept = true;
        } finally {
            if (!noexcept)
                setSubmitting(false);
        }
    }
}
