import React, { useState } from 'react';
import { Button, Card, InputGroup, FormControl, Row, Col } from 'react-bootstrap';
import { Redirect, useLocation } from 'react-router-dom';
import ToastsHost from './ToastsHost';
import ValidationErrors from './ValidationErrors';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { actionValidationError } from '../redux-local/Toasts';

export default props => {
    const location = useLocation();
    const dispatch = useDispatch();

    const [done, setDone] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    if (done)
        return <Redirect to="/" />;

    const query = location.search;
    const queryMatch = /(^\?|&)t=([^&]+)(&|$)/.exec(query);
    if (!queryMatch)
        return <Redirect to="/" />;

    const token = decodeURIComponent(queryMatch[2]);

    return <div className="login-page">
        <div className="login-box">
            {/*<!-- /.login-logo -->*/}
            <Card className="card card-outline card-primary">
                <Card.Header className="card-header text-center">
                    <h1 className="brand-text font-weight-light text-center">easy<span className="text-primary">support</span></h1>
                </Card.Header>
                <Card.Body className="card-body">
                    <InputGroup className="mb-3">
                        <FormControl value={password} onChange={e => setPassword(e.target.value)} onKeyDown={e => e.key === 'Enter' ? onReset() : null} disabled={submitting} type="password" className="form-control" placeholder="Password" />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                <span className="fas fa-lock"></span>
                            </InputGroup.Text>
                        </InputGroup.Append>
                        <ValidationErrors errors={validationErrors} campo="password" />
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <FormControl value={passwordCheck} onChange={e => setPasswordCheck(e.target.value)} onKeyDown={e => e.key === 'Enter' ? onReset() : null} disabled={submitting} type="password" className="form-control" placeholder="Ripeti password" />
                        <InputGroup.Append>
                            <InputGroup.Text>
                                <span className="fas fa-lock"></span>
                            </InputGroup.Text>
                        </InputGroup.Append>
                        <ValidationErrors errors={validationErrors} campo="passwordCheck" />
                    </InputGroup>
                    <Row>
                        <Col md={{ span: 12 }}>
                            <Button onClick={onReset} disabled={submitting} type="submit" className="btn btn-primary btn-block">Reimposta password</Button>
                        </Col>
                        {/*<!-- /.col -->*/}
                    </Row>
                </Card.Body>
                {/*<!-- /.card-body -->*/}
            </Card>
            {/*<!-- /.card -->*/}
        </div>
        <ToastsHost />
    </div>;

    function onReset() {
        const erroriLocali = [];

        if (password == '')
            erroriLocali.push({ campo: 'password', errore: 'Inserire una password' });
        else {
            if (password != passwordCheck)
                erroriLocali.push({ campo: 'passwordCheck', errore: 'Le password non corrispondono' });

            if (password.length < 8)
                erroriLocali.push({ campo: 'password', errore: 'La password deve essere lunga almeno otto caratteri' });
            if (!/[a-z]/.test(password))
                erroriLocali.push({ campo: 'password', errore: 'La password deve contenere almeno una lettera minuscola' });
            if (!/[A-Z]/.test(password))
                erroriLocali.push({ campo: 'password', errore: 'La password deve contenere almeno una lettera maiuscola' });
            if (!/[0-9]/.test(password))
                erroriLocali.push({ campo: 'password', errore: 'La password deve contenere almeno un numero' });
        }

        setValidationErrors(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        var noexcept = false;
        setSubmitting(true);
        try {
            const postData = {
                base64Token: token,
                password: password
            };

            axios
                .post('/api/Utenti/Recupera', postData, { validateStatus: s => s == 200 || s == 403 })
                .then(r => {
                    switch (r.status) {
                        case 200:
                            setDone(true);
                            break;
                        case 403:
                            dispatch(actionValidationError({
                                key: 'recovery-invalid',
                                value: 'La richiesta di recupero password non è valida'
                            }));
                            break;
                    }
                });
        } finally {
            if (!noexcept)
                setSubmitting(false);
        }
    }
};
