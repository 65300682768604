import axios from 'axios';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ValidationErrors from './ValidationErrors';

export default props => {
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [passwordGenera, setPasswordGenera] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');

    const [validationErrors, setValidationErrors] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Card.Title className="card-title">Registra un nuovo utente</Card.Title>
            </Card.Header>
            <Form>
                <Card.Body className="card-body">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Nome utente</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={username} onChange={e => setUsername(e.target.value)}
                                disabled={submitting} className="form-control" type="text" />
                            <ValidationErrors errors={validationErrors} campo="username" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Nome visibile</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={displayName} onChange={e => setDisplayName(e.target.value)}
                                disabled={submitting} className="form-control" type="text" />
                            <ValidationErrors errors={validationErrors} campo="displayName" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>E-Mail</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={email} onChange={e => setEmail(e.target.value)}
                                disabled={submitting} className="form-control" type="email" />
                            <ValidationErrors errors={validationErrors} campo="email" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalCheck">
                        <Col sm={{ span: 10, offset: 2 }}>
                            <Form.Check
                                checked={passwordGenera} onChange={e => setPasswordGenera(e.target.checked)}
                                disabled={submitting} label="Genera password automaticamente" />
                        </Col>
                    </Form.Group>
                    {passwordGenera ? null : <>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Password</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    value={password} onChange={e => setPassword(e.target.value)}
                                    disabled={submitting} className="form-control" type="password" />
                                <ValidationErrors errors={validationErrors} campo="password" />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm={2}>Conferma Password</Form.Label>
                            <Col sm={10}>
                                <Form.Control
                                    value={passwordCheck} onChange={e => setPasswordCheck(e.target.value)}
                                    disabled={submitting} className="form-control" type="password" />
                                <ValidationErrors errors={validationErrors} campo="passwordCheck" />
                            </Col>
                        </Form.Group>
                    </>}
                </Card.Body>
                <Card.Footer>
                    <Button
                        onClick={onUtenteSubmit} disabled={submitting} variant="primary"
                        block className="mt-5 col-sm-3">Salva</Button>
                </Card.Footer>
            </Form>
        </Card>
    </Col>;

    function onUtenteSubmit() {
        var erroriLocali = [];
        if (username === '')
            erroriLocali.push({ campo: 'username', errore: 'Inserire uno username' });
        if (displayName === '')
            erroriLocali.push({ campo: 'displayName', errore: 'Inserire un nome visibile' });
        if (email === '')
            erroriLocali.push({ campo: 'email', errore: 'Inserire una e-mail' });
        else if (!/^[^@]+@([^\.]+\.)+[^\.]+$/.test(email))
            erroriLocali.push({ campo: 'email', errore: 'Inserire una e-mail valida' });
        if (!passwordGenera) {
            if (password == '')
                erroriLocali.push({ campo: 'password', errore: 'Inserire una password' });
            else {
                if (password != passwordCheck)
                    erroriLocali.push({ campo: 'passwordCheck', errore: 'Le password non corrispondono' });

                if (password.length < 8)
                    erroriLocali.push({ campo: 'password', errore: 'La password deve essere lunga almeno otto caratteri' });
                if (!/[a-z]/.test(password))
                    erroriLocali.push({ campo: 'password', errore: 'La password deve contenere almeno una lettera minuscola' });
                if (!/[A-Z]/.test(password))
                    erroriLocali.push({ campo: 'password', errore: 'La password deve contenere almeno una lettera maiuscola' });
                if (!/[0-9]/.test(password))
                    erroriLocali.push({ campo: 'password', errore: 'La password deve contenere almeno un numero' });
            }
        }

        setValidationErrors(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        const postData = {
            generatePassword: passwordGenera,
            username: username,
            password: password,
            displayName: displayName,
            email: email
        };

        setSubmitting(true);
        axios
            .post('/api/Utenti', postData)
            .finally(() => setSubmitting(false))
            .then(r => r.data)
            .then(d => history.replace(d.userUrl));
    }
};
