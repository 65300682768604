import axios from 'axios';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ValidationErrors from './ValidationErrors';

export default props => {
    const history = useHistory();

    const [ragioneSociale, setRagioneSociale] = useState('');
    const [partitaIva, setPartitaIva] = useState('');
    const [indirizzo, setIndirizzo] = useState('');
    const [città, setCittà] = useState('');
    const [provincia, setProvincia] = useState('');
    const [email, setEmail] = useState('');
    const [referente, setReferente] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [validationErrors, setValidationErrors] = useState([]);

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Card.Title className="card-title">Registra un nuovo cliente</Card.Title>
            </Card.Header>
            <Form as="div">
                <Card.Body className="card-body">
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Ragione Sociale</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={ragioneSociale} onChange={e => setRagioneSociale(e.target.value)}
                                className="form-control" type="text" />
                            <ValidationErrors errors={validationErrors} campo="ragioneSociale" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Partita IVA</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={partitaIva} onChange={e => setPartitaIva(e.target.value)}
                                className="form-control" type="text" />
                            <ValidationErrors errors={validationErrors} campo="partitaIva" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Indirizzo</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={indirizzo} onChange={e => setIndirizzo(e.target.value)}
                                className="form-control" type="text" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Città</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={città} onChange={e => setCittà(e.target.value)}
                                className="form-control" type="text" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Provincia</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={provincia} onChange={e => setProvincia(e.target.value)}
                                className="form-control" type="text" />
                            <ValidationErrors errors={validationErrors} campo="provincia" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>E-Mail</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={email} onChange={e => setEmail(e.target.value)}
                                className="form-control" type="text" />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={2}>Referente</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                value={referente} onChange={e => setReferente(e.target.value)}
                                className="form-control" type="text" />
                        </Col>
                    </Form.Group>
                </Card.Body>
                <Card.Footer>
                    <Button
                        disabled={submitting} onClick={onSubmit} variant="primary"
                        block className="mt-5 col-sm-3">Salva</Button>
                </Card.Footer>
            </Form>
        </Card>
    </Col>;

    function onSubmit() {
        if (submitting)
            return;
        var erroriLocali = [];
        if (ragioneSociale === '')
            erroriLocali.push({ campo: 'ragioneSociale', errore: 'Inserire la ragione sociale' });
        if (partitaIva === '')
            erroriLocali.push({ campo: 'partitaIva', errore: 'Inserire la partita IVA' });
        else if (!/^\d{11}$/.test(partitaIva))
            erroriLocali.push({ campo: 'partitaIva', errore: 'La partita IVA deve contenere 11 numeri' });
        if (provincia !== '')
            if (!/^[a-z]{2}$/i.test(provincia))
                erroriLocali.push({ campo: 'provincia', errore: 'La provincia deve contenere 2 lettere' });

        setValidationErrors(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        const data = {
            ragioneSociale: ragioneSociale,
            partitaIva: partitaIva,
            indirizzo: indirizzo,
            città: città,
            provincia: provincia,
            email: email,
            referente: referente
        };

        setSubmitting(true);
        axios
            .post('/api/Clienti', data)
            .finally(() => setSubmitting(false))
            .then(r => r.data)
            .then(d => { history.replace(d.url); console.log(d.url) });
    }
};
