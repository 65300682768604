import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Table, Button, FormGroup, FormLabel, Row, Form } from 'react-bootstrap';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import 'rc-time-picker/assets/index.css';
import FileUploader from './FileUploader';

const MinutiEditor = ({ value, onChange, type, gravitàId, contatore, ...props }) => {
    const [focused, setFocused] = useState(false);

    var valueFormatted;

    if (!value)
        valueFormatted = '';
    else if (focused)
        valueFormatted = value;
    else
        valueFormatted = ''.concat(value, ' Minuti')

    return <Form.Control
        value={valueFormatted} onChange={e => onChange(contatore, gravitàId, e.target.value)} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}
        type={focused ? type : "text"} {...props}></Form.Control>;
}

const FasciaEditRow = ({ value, giorno, onChange, className, giornoLabel, onToggleAttivo }) => {
    const [checked, setChecked] = useState(true)
    return <>
        <Form.Check
            type="switch"
            id={giorno}
            checked={value.attivo ? checked : !checked}
            onChange={() => onToggleAttivo(giorno)}
        />
        <Form.Label column md={2}>{giornoLabel}</Form.Label>
        <Form.Label column md={3} className="text-right">Ora inizio</Form.Label>
        <TimePicker
            onChange={e => onChange(giorno, 'inizio', e && e.format('HH:mm:ss'))} disabled={!value.attivo} showSecond={false}
            value={moment(!value ? null : value.inizio, "HH:mm:ss")} allowEmpty={false} />
        <Form.Label column md={2} className="text-right">Ora fine</Form.Label>
        <TimePicker
            onChange={e => onChange(giorno, 'fine', e && e.format('HH:mm:ss'))} disabled={!value.attivo} showSecond={false}
            value={moment(!value ? null : value.fine, "HH:mm:ss")} allowEmpty={false} />
    </>
}

var previuosId = 0;

export default props => {
    const match = useRouteMatch();
    const contrattoId = match.params.id;

    const [contratto, setContratto] = useState({});
    const [noteAssistenza, setNoteAssistenza] = useState('');

    const [gravitaLista, setGravitaLista] = useState([]);
    const [statiLista, setStatiLista] = useState([]);
    const [contatoriLista, setContatoriLista] = useState([]);
    const [assistenzaFasceLista, setAssistenzaFasceLista] = useState({
        lun: {},
        mar: {},
        mer: {},
        gio: {},
        ven: {},
        sab: {},
        dom: {}
    });

    const [submitting, setSubmitting] = useState(false);
    const [allegatoAssistenza, setAllegatoAssistenza] = useState(null);
    const [allegatoAssistenzaDataUri, setAllegatoAssistenzaDataUri] = useState('');

    useEffect(() => {
        if (!allegatoAssistenza) {
            setAllegatoAssistenzaDataUri('');
            return;
        }

        var reader = new FileReader();
        reader.onload = e => setAllegatoAssistenzaDataUri(e.target.result);
        reader.readAsDataURL(allegatoAssistenza);

        return () => {
            if (reader.readyState === 'LOADING')
                reader.abort();
        };
    }, [allegatoAssistenza]);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        axios
            .get('/api/Contratti/'.concat(contrattoId), { cancelToken: cancelToken.token })
            .then(r => r.data)
            .then(d => {
                setContratto(d);
                setStatiLista(d.stati);
                setGravitaLista(d.gravità);
                setContatoriLista(d.contatori);
                setNoteAssistenza(d.noteAssistenza);

                const assistenzaFasceLista = d.assistenzaFasce;
                ['lun', 'mar', 'mer', 'gio', 'ven', 'sab', 'dom'].forEach(e => {
                    if (!assistenzaFasceLista[e])
                        assistenzaFasceLista[e] = {
                            inizio: '09:00:00',
                            fine: '18:00:00',
                            attivo: false
                        };
                    else
                        assistenzaFasceLista[e].attivo = true;
                });

                setAssistenzaFasceLista(assistenzaFasceLista);
            });

        return () => cancelToken.cancel();
    }, [contrattoId]);

    const updateGravità = (gravita, nome) => {
        setGravitaLista(gravitaLista.map(e => {
            if (e !== gravita)
                return e;
            else
                return {
                    ...e,
                    descrizione: nome,
                    modificata: true
                };
        }));
    };

    const aggiungiGravità = () => {
        setGravitaLista([...gravitaLista, {
            descrizione: '',
            nuovo: true,
            id: previuosId--
        }])
    }

    const salvaGravità = () => {
        axios
            .patch('/api/Contratti/'.concat(contrattoId, '/Gravita'), { gravita: gravitaLista })
            .then(r => axios
                .get('/api/Contratti/'.concat(contrattoId))
                .then(r => r.data)
                .then(d => setGravitaLista(d.gravità)));

    }

    const updateStato = (stato, nome) => {
        setStatiLista(statiLista.map(e => {
            if (e !== stato)
                return e;
            else
                return {
                    ...e,
                    descrizione: nome,
                    modificato: true
                };
        }));
    };

    const aggiungiStato = () => {
        setStatiLista([...statiLista, {
            descrizione: '',
            nuovo: true
        }])
    }

    const salvaStato = () => {
        axios
            .patch('/api/Contratti/'.concat(contrattoId, '/Stati'), { stati: statiLista })
            .then(r => axios
                .get('/api/Contratti/'.concat(contrattoId))
                .then(r => r.data)
                .then(d => setStatiLista(d.stati))
            );
    }

    const updateFascia = (giorno, prop, value) => {
        setAssistenzaFasceLista({
            ...assistenzaFasceLista,
            [giorno]: {
                ...assistenzaFasceLista[giorno],
                [prop]: value
            }
        });
    }

    const toggleAttivo = (giorno) => {
        setAssistenzaFasceLista({
            ...assistenzaFasceLista,
            [giorno]: {
                ...assistenzaFasceLista[giorno],
                attivo: !assistenzaFasceLista[giorno].attivo
            }
        });
    }

    const salvaFasceAssistenza = () => {
        const fasceModificate = {};
        Object.keys(assistenzaFasceLista).map((e, i) => {
            if (assistenzaFasceLista[e].attivo)
                fasceModificate[e] = {
                    inizio: assistenzaFasceLista[e].inizio,
                    fine: assistenzaFasceLista[e].fine
                }
        })

        axios
            .patch('/api/Contratti/'.concat(contrattoId, '/FasceAssistenza'), { fasceAssistenza: fasceModificate })
            .then(r => axios
                .get('/api/Contratti/'.concat(contrattoId))
                .then(r => r.data)
            );
    }

    const updateContatoreNome = (contatore, nome) => {
        setContatoriLista(contatoriLista.map(e => {
            if (e !== contatore)
                return e;
            else
                return {
                    ...e,
                    descrizione: nome,
                    modificato: true,
                };
        }));
    }

    const updateContatoreGravità = (contatore, gravitàId, value) => {
        var valueNumber;
        if (isNaN(value))
            valueNumber = null;
        else
            valueNumber = Number(value);
        setContatoriLista(contatoriLista.map(e => {
            if (e !== contatore)
                return e;
            else
                return {
                    ...e,
                    gravitàSoglie: {
                        ...e.gravitàSoglie,
                        [gravitàId]: valueNumber
                    }
                };
        }))
    }

    const salvaContatori = () => {
        axios
            .patch('/api/Contratti/'.concat(contrattoId, '/Contatori'), { contatori: contatoriLista })
            .then(r => axios
                .get('/api/Contratti/'.concat(contrattoId))
                .then(r => r.data)
            );
    }

    const onNoteAssistenzaSalva = () => {
        axios
            .patch('/api/Contratti/'.concat(contrattoId), { noteAssistenza: noteAssistenza })
            .then(r => setContratto({
                ...contratto,
                noteAssistenza: r.data.noteAssistenza
            }));
    };

    const onAllegatoAssistenzaSalva = () => {
        if (submitting)
            return;

        axios
            .post('/api/Contratti/'.concat(contrattoId, '/Allegati'), {
                filename: allegatoAssistenza.name,
                dataUri: allegatoAssistenzaDataUri
            })
            .then(r => {
                axios
                    .get("/api/Contratti/".concat(contrattoId))
                    .then(r => r.data)
                    .then(d => {
                        setContratto(d);
                        setNoteAssistenza(d.noteAssistenza);
                    });
            });
    };

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Row>
                    <h5>Contratto: <span>{contratto.descrizione}</span></h5>
                    <Link to={contratto.clienteViewUrl} className="col-md-3 btn btn-primary ml-auto">Torna al cliente</Link>
                </Row>
            </Card.Header>
            <Form>
                <Card.Body>
                    <Table striped>
                        <tbody>
                            <tr>
                                <td>Tipo contratto</td>
                                <td>{contratto.descrizione}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Form.Group as={Row} className="mt-2">
                        <Form.Label column md={2}>Gravità</Form.Label>
                        <Col md={10}>
                            <Row>
                                {gravitaLista.map(e => <Form.Control
                                    key={e.id} value={e.descrizione}
                                    onChange={evt => updateGravità(e, evt.target.value)}
                                    type="text" />
                                )}
                            </Row>
                        </Col>
                        <Button onClick={aggiungiGravità} className="col-md-2 mt-3 offset-2" variant="primary">Aggiungi Gravità</Button>
                        <Button onClick={salvaGravità} className="col-md-2 mt-3 ml-auto" variant="success">Salva modifiche</Button>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={2}>Stati</Form.Label>
                        <Col md={10}>
                            <Row>
                                {statiLista.map(e => <Form.Control
                                    key={e.id} value={e.descrizione}
                                    onChange={evt => updateStato(e, evt.target.value)} type="text" />
                                )}
                            </Row>
                        </Col>

                        <Button onClick={aggiungiStato} className="col-md-2 mt-3 offset-2" variant="primary">Aggiungi Stato</Button>
                        <Button onClick={salvaStato} className="col-md-2 mt-3 ml-auto" variant="success">Salva modifiche</Button>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={12}>Orari assistenza</Form.Label>
                        <Col md={12}>
                            <Row>
                                <FasciaEditRow className='mt-2'
                                    value={assistenzaFasceLista.lun}
                                    onChange={updateFascia}
                                    onToggleAttivo={toggleAttivo}
                                    giorno={'lun'}
                                    giornoLabel={'Lunedì'}
                                />
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <FasciaEditRow className='mt-2'
                                    value={assistenzaFasceLista.mar}
                                    onChange={updateFascia}
                                    onToggleAttivo={toggleAttivo}
                                    giorno={'mar'}
                                    giornoLabel={'Martedì'}
                                />
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <FasciaEditRow className='mt-2'
                                    value={assistenzaFasceLista.mer}
                                    onChange={updateFascia}
                                    onToggleAttivo={toggleAttivo}
                                    giorno={'mer'}
                                    giornoLabel={'Mercoledì'}
                                />
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <FasciaEditRow className='mt-2'
                                    value={assistenzaFasceLista.gio}
                                    onChange={updateFascia}
                                    onToggleAttivo={toggleAttivo}
                                    giorno={'gio'}
                                    giornoLabel={'Giovedì'}
                                />
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <FasciaEditRow className='mt-2'
                                    value={assistenzaFasceLista.ven}
                                    onChange={updateFascia}
                                    onToggleAttivo={toggleAttivo}
                                    giorno='ven'
                                    giornoLabel={'Venerdì'}
                                />
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>

                                <FasciaEditRow className='mt-2'
                                    value={assistenzaFasceLista.sab}
                                    onChange={updateFascia}
                                    onToggleAttivo={toggleAttivo}
                                    giorno={'sab'}
                                    giornoLabel={'Sabato'}
                                />
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <FasciaEditRow className='mt-2'
                                    value={assistenzaFasceLista.dom}
                                    onChange={updateFascia}
                                    onToggleAttivo={toggleAttivo}
                                    giorno={'dom'}
                                    giornoLabel={'Domenica'}
                                />
                            </Row>
                        </Col>
                        <Button className="col-md-2 mt-3 ml-auto" variant="success" onClick={salvaFasceAssistenza}>Salva modifiche</Button>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={12}>Contatori SLA</Form.Label>
                        {contatoriLista.map(cont => <Col md={12} key={cont.id}>
                            <Row>
                                <Col md={2}>
                                    <Form.Group className="col-md-12">
                                        <Form.Label className="text-right">Nome Contatore</Form.Label>
                                        <Form.Control type="text" value={cont.descrizione} onChange={evt => updateContatoreNome(cont, evt.target.value)}></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={10}>
                                    <Row>
                                        {gravitaLista.map(grav => <Form.Group key={grav.id} className="col-md-3">
                                            <Form.Label className="text-right">{grav.descrizione}</Form.Label>
                                            <MinutiEditor value={cont.gravitàSoglie[grav.id]}
                                                type="number"
                                                gravitàId={grav.id}
                                                contatore={cont}
                                                onChange={updateContatoreGravità}
                                            />
                                        </Form.Group>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>)}
                        <Button onClick={salvaContatori} className="col-md-2 mt-3 ml-auto" variant="success">Salva modifiche</Button>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Form.Label column md={2}>Note assistenza</Form.Label>
                        <Col md={10}>
                            <Row>
                                <Form.Control as="textarea" value={noteAssistenza} onChange={e => setNoteAssistenza(e.target.value)}></Form.Control>
                            </Row>
                        </Col>
                        <Button onClick={onNoteAssistenzaSalva} disabled={noteAssistenza === contratto.noteAssistenza} className="col-md-2 mt-3 ml-auto" variant="success">Salva modifiche</Button>
                    </Form.Group>

                    <FormGroup as={Row}>
                        <Form.Label column md={2}>Allegati assistenza</Form.Label>
                        <Col md={10}>
                            <Row>
                                <FileUploader onChange={e => setAllegatoAssistenza(e.target.files[0])} className="btn btn-light"
                                    custom data-browse="Sfoglia" label={allegatoAssistenza ? allegatoAssistenza.name : 'Seleziona un file'}
                                    onChange={e => setAllegatoAssistenza(e.target.files.length > 0 ? e.target.files[0] : null)} />
                            </Row>
                        </Col>
                        {(contratto.allegati || []).map(e => <Col key={e.url}>
                            <Link to={e.url} target="_blank">{e.nome}</Link>
                        </Col>)}
                        <Button onClick={onAllegatoAssistenzaSalva} disabled={!allegatoAssistenzaDataUri} className="col-md-2 mt-3 ml-auto" variant="success">Carica</Button>
                    </FormGroup>

                </Card.Body>
            </Form>
        </Card>
    </Col>;
}