import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router';
import { Link, Switch } from 'react-router-dom';
import { actionLogout } from '../redux-local/CurrentUser';

export default props => {
    const dispatch = useDispatch();
    const history = useHistory();

    const currentUser = useSelector(store => store.currentUser);

    return <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li className="nav-item">
            <Button variant="outline-light" className="text-left nav-link" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: '#c2c7d0', cursor: 'default' }}>
                <i></i>
                <p> {currentUser ? currentUser.displayName : null}</p>
            </Button>
        </li>
        <li className="nav-item">
            <Button onClick={() => dispatch(onLogout)} variant="outline-light" className="nav-link text-left border-0 text-light">
                <i className="fas fa-sign-out-alt mr-1" style={{ fontSize: '18px' }}></i>
                <p> Logout</p>
            </Button>
        </li>
        <li className="nav-item">
            <Switch>
                <Route path="/Utente/CambioPassword">
                    <Link to="/Utente/CambioPassword" variant="outline-light" className="nav-link text-light active">
                        <i className="fas fa-lock mr-1" style={{ fontSize: '18px' }}></i>
                        <p> Cambia Password</p>
                    </Link>
                </Route>
                <Route>
                    <Link to="/Utente/CambioPassword" variant="outline-light" className="nav-link text-light">
                        <i className="fas fa-lock mr-1" style={{ fontSize: '18px' }}></i>
                        <p>Cambia Password</p>
                    </Link>
                </Route>
            </Switch>
        </li>
    </ul>;

    function onLogout(dispatch, getState) {
        actionLogout()(dispatch, getState)
            .then(() => history.push('/'), e => console.error(e));
    }
};
