import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default props => {
    const [utenti, setUtenti] = useState([]);
    useEffect(() => {
        axios
            .get('/api/Utenti')
            .then(r => r.data)
            .then(d => setUtenti(d.utenti));
    }, []);

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Row>
                    <h3 className="col-md-3 col-sm-12">Utenti</h3>
                    <Link to="/Admin/Utente/Nuovo" className="btn btn-primary col-md-3 col-sm-12 ml-auto">Registra nuovo utente</Link>
                </Row>
            </Card.Header>
            <Card.Body>
                <Table striped>
                    <thead>
                        <tr>
                            <th>Nome Utente</th>
                        </tr>
                    </thead>
                    <tbody>
                        {utenti.map((e, i) => <tr key={i}>
                            <td>
                                <Link
                                    to={e.url}>
                                    {e.displayName}
                                </Link>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </Col>;
};
