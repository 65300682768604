import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default props => {
    const [clienti, setClienti] = useState([]);

    useEffect(() => {
        axios
            .get('/api/Clienti')
            .then(r => r.data)
            .then(d => { setClienti(d.clienti); console.log(clienti) });
    }, []);

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Row>
                    <h3 className="col-md-3 col-sm-12">Clienti</h3>
                    <Link to="/Admin/Cliente/Nuovo" className="btn btn-primary col-md-3 col-sm-12 ml-auto">Registra nuovo cliente</Link>
                </Row> 
            </Card.Header>
            <Card.Body>                
                <Table striped>
                    <thead>
                        <tr>
                            <th>Ragione Sociale</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clienti.map((e, i) => <tr key={i}>
                            <td>
                                <Link
                                    to={e.url}>
                                    {e.ragioneSociale}
                                </Link>
                            </td>
                        </tr>)}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </Col>;
};
