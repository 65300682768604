const defaultState = {
    sliding: false,
    collapsed: false
}

export const actionSetSliding = sliding => ({
    type: 'sidebar-sliding',
    data: sliding
});

export const actionSetCollapsed = collapsed => ({
    type: 'sidebar-collapsed',
    data: collapsed
});
export const actionToggleCollapsed = () => ({
    type: 'sidebar-collapsed',
    data: 'toggle'
});

export const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'sidebar-sliding':
            return {
                ...state,
                sliding: action.data
            };
        case 'sidebar-collapsed':
            return {
                ...state,
                collapsed: action.data === 'toggle' ? !state.collapsed : action.data
            };
        default:
            return state;
    }
};
