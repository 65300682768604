import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';

export default class MyStatefulEditor extends Component {
    static propTypes = {
        onChange: PropTypes.func
    };

    static toolbarConfig = {
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Grassetto', style: 'BOLD', className: 'custom-css-class' },
            { label: 'Corsivo', style: 'ITALIC' },
            { label: 'Sottolineato', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'Elenco puntato', style: 'unordered-list-item' },
            { label: 'Elenco numerato', style: 'ordered-list-item' }
        ],
        HISTORY_BUTTONS: {
            undo: { label: 'Annulla' },
            redo: { label: 'Ripeti' }
        }
    };

    state = {
        value: RichTextEditor.createEmptyValue()
    }

    onChange = (value) => {
        this.setState({ value });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(value.toString('markdown'));
        }
    };

    render() {
        return (
            <RichTextEditor
                toolbarConfig={MyStatefulEditor.toolbarConfig}
                value={this.state.value}
                onChange={this.onChange} />
        );
    }
}