import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionValidationError } from '../redux-local/Toasts';
import FileUploader from './FileUploader';
import ValidationErrors from './ValidationErrors';
import RichTextEditor from './RichTextEditor';


export default props => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [clientiLista, setClientiLista] = useState([]);
    const [cliente, setCliente] = useState(null);

    const [contrattiLista, setContrattiLista] = useState([]);
    const [contratto, setContratto] = useState(null);

    const [gravitàLista, setGravitàLista] = useState([]);
    const [gravità, setGravità] = useState(null);

    const [titolo, setTitolo] = useState('');
    const [richiesta, setRichiesta] = useState('');

    const [erroriValidazione, setErroriValidazione] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const [fileObject, setFileObject] = useState(null);
    const [fileDataUri, setFileDataUri] = useState(null);

    useEffect(() => {
        if (!fileObject)
            return;

        var reader = new FileReader();
        reader.onload = e => setFileDataUri(e.target.result);
        reader.readAsDataURL(fileObject);

        return () => {
            if (reader.readyState === 'LOADING')
                reader.abort();
        };
    }, [fileObject]);

    useEffect(() => {
        axios
            .get('/api/Clienti?ticketCanCreate')
            .then(r => r.data)
            .then(d => {
                var responseClientiLista = d.clienti;

                setClientiLista(responseClientiLista);
                setCliente(responseClientiLista.length > 0 ? responseClientiLista[0].apiUrl : null);
            });
    }, []);

    useEffect(() => {
        if (!cliente) {
            setContrattiLista([]);
            setContratto(null);
            return;
        }

        const cancelToken = axios.CancelToken.source();
        axios
            .get(cliente.concat('/Contratti'), { cancelToken: cancelToken.token })
            .then(r => r.data)
            .then(d => {
                var responseContrattiLista = d.contratti;

                setContrattiLista(responseContrattiLista);
                setContratto(responseContrattiLista.length > 0 ? responseContrattiLista[0].apiUrl : null);
            });

        return () => {
            cancelToken.cancel();
        };
    }, [cliente]);

    useEffect(() => {
        if (!contratto) {
            setGravitàLista([]);
            setGravità(null);
            return;
        }

        const cancelToken = axios.CancelToken.source();
        axios
            .get(contratto.concat('/Gravità'), { cancelToken: cancelToken.token })
            .then(r => r.data)
            .then(d => {
                var responseGravitàLista = d.gravita;

                setGravitàLista(responseGravitàLista);
                setGravità(responseGravitàLista.length > 0 ? String(responseGravitàLista[0].id) : null);
            });
    }, [contratto]);

    const canSubmit =
        !submitting &&
        ((fileObject && fileDataUri) || (!fileObject && !fileDataUri));

    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header className="card-header col-12 bg-light">
                <Card.Title>Apri un nuovo ticket</Card.Title>
            </Card.Header>
            <Card.Body>
                {!(clientiLista && clientiLista.length > 1) ? null : <Form.Group as={Row}>
                    <Form.Label column sm={3}>Cliente</Form.Label>
                    <Col sm={9}>
                        <Form.Control as="select"
                            value={cliente} onChange={e => setCliente(e.target.value)}
                            disabled={submitting} className="form-control">
                            {clientiLista.map(e =>
                                <option value={e.apiUrl} key={e.apiUrl}>{e.ragioneSociale}</option>)}
                        </Form.Control>
                    </Col>
                </Form.Group>}
                {!(contrattiLista && contrattiLista.length > 1) ? null : <Form.Group as={Row}>
                    <Form.Label column sm={3}>Contratto</Form.Label>
                    <Col sm={9}>
                        <Form.Control as="select"
                            value={contratto} onChange={e => setContratto(e.target.value)}
                            disabled={submitting} className="form-control">
                            {contrattiLista.map(e => <option value={e.apiUrl} key={e.apiUrl}>{e.descrizione}</option>)}
                        </Form.Control>
                    </Col>
                </Form.Group>}
                {!(gravitàLista && gravitàLista.length > 1) ? null : <Form.Group as={Row}>
                    <Form.Label column sm={3}>Gravità</Form.Label>
                    <Col sm={9}>
                        <Form.Control as="select"
                            value={gravità} onChange={e => setGravità(e.target.value)}
                            disabled={submitting} className="form-control">
                            {gravitàLista.map(e => <option value={String(e.id)} key={String(e.id)}>{e.descrizione}</option>)}
                        </Form.Control>
                    </Col>
                </Form.Group>}
                <Form.Group as={Row}>
                    <Form.Label column sm={3}>Oggetto <span className="small">(*Campo Obbligatorio)</span></Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            value={titolo} onChange={e => setTitolo(e.target.value)}
                            disabled={submitting} className="form-control"
                            type="text" placeholder="Titolo della richiesta"
                            maxLength={130} />
                        <ValidationErrors errors={erroriValidazione} campo="titolo" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={3}>Descrizione <span className="small">(*Campo Obbligatorio)</span></Form.Label>
                    <Col sm={9}>
                        <RichTextEditor
                            onChange={(e) => setRichiesta(e)} disabled={submitting}
                        />
                        <ValidationErrors errors={erroriValidazione} campo="richiesta" />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
            <Form.Label column sm={3}>Allegato <span className="small">(2MB max)</span></Form.Label>
                    <Col sm={9}>
                        <FileUploader
                            custom label={fileObject ? fileObject.name : 'Seleziona un file' } className="btn btn-light text-left pt-2"
                            onChange={e => setFileObject(e.target.files.length > 0 ? e.target.files[0] : null)} />
                        <ValidationErrors errors={erroriValidazione} campo="file" />
                    </Col>
                </Form.Group>
            </Card.Body>
            <Card.Footer>
                <Button disabled={!canSubmit} onClick={onSubmit} className="btn-block mt-2 col-sm-3">Salva</Button>
            </Card.Footer>
        </Card>
    </Col>;

    function onSubmit() {
        const erroriLocali = [];

        if (titolo.trim() === '')
            erroriLocali.push({ campo: 'titolo', errore: 'Inserire un oggetto della richiesta' });
        if (richiesta.trim().charCodeAt(0) === 8203 || richiesta.trim() === '')
            erroriLocali.push({ campo: 'richiesta', errore: 'Inserire una descrizione della richiesta' });
        if (fileObject !== null && fileObject.size > 2 * 1024 * 1024)
            erroriLocali.push({ campo: 'file', errore: 'Il file deve essere meno di 2MB' });

        setErroriValidazione(erroriLocali);
        if (erroriLocali.length > 0)
            return;

        setSubmitting(true);
        var noexcept = false;
        try {
            onDataObjectReady({
                titolo: titolo,
                richiesta: richiesta,
                cliente: clientiLista.find(e => e.apiUrl === cliente).uri,
                contratto: contrattiLista.find(e => e.apiUrl === contratto).descrizione,
                gravita: gravitàLista.find(e => e.id === Number(gravità)).descrizione,
                allegato: fileDataUri,
                allegatoNome: fileObject ? fileObject.name : null,
            });

            noexcept = true;
        }
        finally {
            if (!noexcept)
                setSubmitting(false);
        }
    }

    function onDataObjectReady(reqData) {
        axios
            .post('/api/Richieste', reqData)
            .finally(() => setSubmitting(false))
            .then(r => {
                const d = r.data;
                history.replace(d.url);
            }, r => {
                switch (r.response.status) {
                    case 401:
                        dispatch(actionValidationError({
                            key: 'unauthenticated',
                            text: "La tua autenticazione non è più valida, effettua un nuovo login"
                        }));
                        history.push('/');
                        break;
                    case 403:
                        dispatch(actionValidationError({
                            key: 'ticket-unauthorized',
                            text: 'Non sei autorizzato a creare un ticket'
                        }));
                        break;
                }
            });
    }
};
