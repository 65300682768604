import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import withAuthentication from '../hoc/withAuthentication';

const TicketList = props => {
    const [richiesteLista, setRichiesteLista] = useState([]);
    const [richiesteListaFiltrate, setRichiesteListaFiltrate] = useState([]);
    const [clienteScelto, setClienteScelto] = useState("");
    const [groups, setGroups] = useState([]);
    const [idClientiList, setIdClientiList] = useState([]);

    useEffect(() => {
        axios
            .get('/api/Richieste')
            .then(r => r.data)
            .then(d => {
                setRichiesteLista(d.tickets);
                setIdClientiList(d.tickets.filter((value, index, self) => {
                    return self.findIndex(v => v.idCliente === value.idCliente) === index;
                }).map(richiesta => {
                    return { idCliente: richiesta.idCliente, ragioneSocialeCliente: richiesta.ragioneSocialeCliente }
                }))
            });
    }, []);

    //const groups = richiesteLista.reduce((acc, value) => {
    //    var sezione = acc.find(e => e.nome === value.stato);
    //    if (!sezione)
    //        acc.push(sezione = { nome: value.stato, tickets: [] });

    //    sezione.tickets.push(value);

    //    return acc;
    //}, []);

    useEffect(() => {
        const listaDaRidurre = richiesteListaFiltrate && richiesteListaFiltrate.length > 0 ? richiesteListaFiltrate : richiesteLista;

        setGroups(listaDaRidurre.reduce((acc, value) => {
            var sezione = acc.find(e => e.nome === value.stato);
            if (!sezione)
                acc.push(sezione = { nome: value.stato, tickets: [] });

            sezione.tickets.push(value);

            return acc;
        }, [])
        );
    }, [richiesteLista, richiesteListaFiltrate])

    const filterTickets = (idCliente) => {
        const id = typeof idCliente === "string" ? parseInt(idCliente) : idCliente;
        if (idCliente === "") {
            setRichiesteListaFiltrate([])
        }
        setRichiesteListaFiltrate(richiesteLista.filter(richiesta => richiesta.idCliente === id));
    }


    return <Col md={{ span: 10, offset: 1 }}>
        <Card>
            <Card.Header>
                <Row>
                    <h3 className="col-md-3 col-sm-12">Tickets</h3>
                    <Form.Control
                        as="select"
                        className="col-md-4 col-sm-12 form-control"
                        value={clienteScelto}
                        onChange={e => { filterTickets(e.target.value); setClienteScelto(e.target.value) }}>
                        <option value="" >Filtra per cliente</option>
                        {idClientiList.map(e =>
                            <option value={e.idCliente} key={e.idCliente}>{e.ragioneSocialeCliente}</option>
                        )}
                    </Form.Control>
                    <Link to="/Ticket/Nuovo" className="btn btn-primary col-md-3 col-sm-12 ml-auto">Apri un nuovo Ticket</Link>
                </Row>
            </Card.Header>
            <Card.Body>
                <Accordion>
                    {groups.map((e, i) => <Card key={e.nome}>

                        <Accordion.Toggle style={{ cursor: 'pointer', color: '#007bff' }} as={Card.Header} variant="link" eventKey={e.nome}>
                            <h5><span className="text-muted">Stato: </span>{e.nome} ({e.tickets.length})</h5>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={e.nome}>
                            <Card.Body>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20px' }}>Codice</th>
                                            <th>Oggetto</th>
                                            <th>Cliente</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {e.tickets.map((e, i) => <tr key={i}>
                                            <td className="text-muted small">{e.codice}</td>
                                            <td className="">
                                                <Link to={'/Ticket/'.concat(e.codice)}>
                                                    {e.titolo}
                                                </Link>
                                            </td>
                                            <td className="">
                                                {e.ragioneSocialeCliente}
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </Card.Body>
                        </Accordion.Collapse>


                    </Card>)}
                </Accordion>
            </Card.Body>
        </Card>
    </Col>;
};

export default withAuthentication(TicketList);
